<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Cost Centre List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>
    <ListTable
        :cost-centres="costCentreList"
        @onEdit="onEditCostCentre"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>

    <CostCentreAddModal
        :modalTitle="costCentre.id ? 'Edit Cost Centre' : 'Add Cost Centre'"
        :showParent="true"
        ref="costCentre"
        :formData="costCentre"
        @onSubmitForm="handleCostCentreSubmit"
        @onCloseCostCentreModal="resetForm"
    />
  </div>
</template>

<script>
import TitleButton from '@/components/atom/TitleButton'
import ListTable
  from '@/components/molecule/procurement/organization-settings/admin-setup/cost-centre/CostCentreListingTable.vue'
import CostCentreAddModal
  from '@/components/molecule/procurement/organization-settings/admin-setup/cost-centre/CostCentreAddModal.vue'

import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import {inject} from "vue";
import handleCostCentres from "@/services/modules/procurement/organization-settings/costCentre";

export default {
  name: 'CostCentreList',

  components: {
    TitleButton,
    ListTable,
    CostCentreAddModal,
    Pagination,
    Loader,
  },

  data: () => ({
    loading: false,
    quick_search: null,
    offset: 20,
    costCentreList: [],
    costCentre: {
      name: '',
      short_name: '',
      code: null,
      description: null,
      status: '',
    },
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    },
  },

  methods: {
    onClickAddNewButton() {
      this.$refs.costCentre.toggleModal()
    },

    onClickSettingButton() {
      alert('Setting Button clicked');
    },

    onClickSearchButton() {
      alert('Search Button clicked');
    },

    resetForm() {
      this.costCentre = {
        name: '',
        short_name: null,
        description: null,
        code: null,
        status: null,
      }
    },

    async handleCostCentreSubmit() {
      this.loading = true

      this.costCentre.company_id = this.$route.params.companyId;
      this.costCentre.user_id = this.$store.getters["user/getUser"].id;
      this.costCentre.updated_by = this.$store.getters["user/getUser"].id;

      try {
        let res = null;

        if (this.costCentre.id) {
          res = await this.updateCostCentre(this.costCentre.id, this.costCentre);
        } else {
          res = await this.createNewCostCentre(this.costCentre);
        }

        if (!res.status) {
          this.showError(res.message);
        } else {
          this.showSuccess(res.message);
          this.resetForm();
          this.onClickAddNewButton();
          await this.getCostCentres();
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        } else if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false;
      }
    },

    async getCostCentres() {
      const query = this.getQuery();
      try {
        this.loading = true;
        let res = await this.fetchCostCentreList(query);
        if (!res.status) {
          this.resetPagination();
        } else if (res.status) {
          this.costCentreList = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },

    getQuery() {
      let query = '?company_id=' + this.companyId
      query += '&offset=' + this.offset
      if (this.page) query += '&page=' + this.page
      return query
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query)
      routeQuery.page = page
      this.$router.push({path: this.$route.path, query: routeQuery})
      setTimeout(() => {
        this.getCostCentres();
      }, 100)
    },

    onEditCostCentre(data) {
      this.costCentre = data;
      this.onClickAddNewButton();
    },

    exportTable() {
      //todo
    },
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      loading,
      fetchCostCentreList,
      createNewCostCentre,
      updateCostCentre,
    } = handleCostCentres();

    return {
      showError,
      showSuccess,
      loading,
      fetchCostCentreList,
      createNewCostCentre,
      updateCostCentre,
    }
  },

  mounted() {
    this.getCostCentres();
  }
}
</script>
